import styles from "./Qualifications.module.css";

function Qualifications() {
  return (
    <div className={styles["qualifications"]}>
      <h2>Formations complémentaires:</h2>
      <ul>
        <li>Psychogénéalogie</li>
        <li>Accompagnement spirituel ignatien</li>
        <li>Bibliodrame</li>
        <li>Supervision</li>
      </ul>
    </div>
  );
}

export default Qualifications;
