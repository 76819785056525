import styles from "./Services.module.css";

function Services() {
  return (
    <div className={styles["services"]}>
      <section className={styles["type"]}>
        <h2>
          <a
            href="https://www.methoderosen.ch/fr/accueil/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Méthode Rosen
          </a>
        </h2>
        <p>
          Ce travail a pour but de se retrouver soi-même &ndash; de transformer
          la personne que nous croyons être en celle que nous sommes réellement
          (Marion Rosen).
        </p>
        <h3>Rosen méthode travail corporel</h3>
        <p>
          En utilisant le toucher et la parole, le praticien accompagne le
          client vers la conscience de soi. Il aide à lâcher des tensions
          musculaires comme émotionnelles.
        </p>
        <p>
          <strong>Prix :</strong> 50 min &ndash; 100 CHF.
        </p>
        <h3>Rosen mouvement</h3>
        <p>
          Les mouvements, exécutés en musique, encouragent la relaxation, la
          lubrification des articulations, la joie de vivre et la reconnexion à
          soi.
          <p>
            <p>
              <strong>Lieu:</strong> Collège Colombières &ndash; GALAXY, Versoix
            </p>
            <a
              href="https://www.aeqv.ch/index.php?page=cours&CleActivite=DROSEN24"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cliquez pour les détails d'inscription
            </a>
          </p>
        </p>
        <p>
          <strong>Prix:</strong> 1h en groupe 9 &ndash; 20 CHF.
        </p>
      </section>

      <section className={styles["type"]}>
        <h2>Massage classique</h2>
        <p>
          Également connu sous le nom de massage suédois, cette forme de massage
          travaille les muscles en profondeur.
          <strong>Prix :</strong> 1h &ndash; 120 CHF (Remboursé par ASCA).
        </p>
      </section>

      <h2>Drainage lymphatique (la méthode Vodder)</h2>
      <p>
        C&rsquo;est un massage léger, doux, rythmé, favorisant et accélérant
        l&rsquo;écoulement de la lymphe.
      </p>
      <p>
        <strong>Prix :</strong> 1h &ndash; 120 CHF (Remboursé par ASCA).
      </p>
    </div>
  );
}

export default Services;
